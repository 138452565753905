<template>
  <section class="terms">
    <general-second :name="page.pageName" :descript="page.description"></general-second>
    <v-container>
      <v-row>
        <v-col cols="12">
          <p>
            The following delivery and payment (General Terms and Conditions - Conditions) apply to all deliveries and services of Industriehandel.net. stand 02.10.2017
          </p>
          <h2>GENERAL</h2>
          <p>
            Orders are accepted on our terms and conditions and executed. Our business, delivery and payment apply without express agreement of all goods and services. Different conditions of the customer are not part of the contracts. Oral and other agreements that deviate from our terms and conditions require our written confirmation.
          </p>
          <h2>
            OFFERS / ORDERS
          </h2>
          <p>
            All offers are subject to, but are binding on the specified offer period. The contract award can be made by mail, fax or e-mail. By placing an order the customer accepts the delivery and payment of the company Industriehandel.net.
          </p>
          <h2>
            PRICES
          </h2>
          <p>
            The prices are, unless otherwise stated in euros. Prices include all costs of production, the individual packaging and shipping. Prices are subject to change at any time without prior notice, as well Herstellpreise, Transport prices / fuel prices and exchange rates fluctuate. In any case, it is appropriate to obtain a binding offer.
          </p>
          <h2>
            DELIVERIES / DELIVERY TIMES / FAD
          </h2>
          <p>
            As a trading company Industriehandel.net is bound delivery times of the manufacturer / supplier. Claims for damages for failure of delivery are excluded. Delivery delays due to force majeure and due to unforeseen circumstances, we are not responsible even if binding promised delivery dates. In these cases we are entitled to withdraw partially or fully from the contract.
          </p>
          <h2>
            COPYRIGHTS
          </h2>
          <p>
            By us or by the manufacturer-made designs, drawings, files and movies remain the property of the designer. When delivered drafts, final drawings or files, we assume the right of reproduction. The Customer warrants that he, goods subscription rights is in possession of all necessary for the treatment and processing by the contractor license, in particular copyrights, among others. It represents the company Industriehandel.net from all claims asserted by third parties due to the violation of such rights. If above assurances -gleich from which Grunde- out to be incorrect, then the client is obliged to compensate any resulting damage. By placing an order the customer accepts these assurances.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import GeneralSecond from '@/components/GeneralSecond'
export default {
  name: 'Terms',
  components: {
    GeneralSecond
  },
  data () {
    return {
      page: {
        pageName: 'General Sales Conditions',
        description: ''
      }
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length > 0) {
      location.href = `${location.href}#${this.$route.params.id}`
    }
  },
  metaInfo: {
    title: 'General Sales Conditions',
    titleTemplate: '%s - VERTEX GmbH',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  }
}
</script>

<style>

</style>
